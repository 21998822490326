import {VALUE_NOT_PRESENT} from '../hooks';

export interface NodeField {
  type: NodeFieldType;
  options?: string[];
  name?: string;
  slug?: string;
  value?: string | typeof VALUE_NOT_PRESENT;
}

export const NodeFieldType = {
  string: 'string',
  number: 'number',
  boolean: 'boolean',
  multilineString: 'multilineString',
} as const;

export type NodeFieldType = keyof typeof NodeFieldType;

export interface NodeInstanceData {
  id: string;
  namespace: string;
  label: string;
  description: string;
  inputs?: NodeIO[];
  outputs?: NodeIO[];
  fields?: NodeField[];
  type: NodeType;
  subscribesTo?: string;
  banner?: string;
}

export interface NodeIO {
  label: string;
  type: string;
  slug: string;
  isFilled?: boolean;
  value?: string;
}

export interface NodeIOExt<T> extends NodeIO {
  elements: T[];
}

export const NodeType = {
  emitter: 'emitter',
  logic: 'logic',
  about: 'about',
  listener: 'listener',
  macro: 'macro',
  value: 'value',
} as const;

export type NodeType = keyof typeof NodeType;
