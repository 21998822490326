// eslint-disable-next-line no-restricted-globals
type Storage = typeof localStorage;

/**
 * @private exported for tests
 */
export class MemoryStorage implements Storage {
  private keys: string[] = [];
  private readonly backing = new Map<string, string>();

  clear(): void {
    this.backing.clear();
  }

  getItem(key: string): string | null {
    return this.backing.get(key) ?? null;
  }

  key(index: number): string | null {
    if (this.keys.length > index) {
      return this.keys[index];
    } else {
      return null;
    }
  }

  get length(): number {
    return this.backing.size;
  }

  removeItem(key: string): void {
    this.backing.delete(key);
    this.keys = Array.from(this.backing.keys());
  }

  setItem(key: string, value: string): void {
    this.backing.set(key, value);
    this.keys = Array.from(this.backing.keys());
  }
}

/**
 * Provides an interface like `localStorage`. Uses the window's or navigator's
 * localStorage APIs if they are available otherwise falls back to an in-memory
 * `Map` structure.
 */
export const storage: Storage =
  // eslint-disable-next-line no-restricted-globals
  typeof localStorage === 'undefined' ? new MemoryStorage() : localStorage;
