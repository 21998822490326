/**
 * An array of arbitrary JSON values.
 */
export type JSONArray = JSONValue[];

/**
 * A representation of an object with string keys and arbitrary JSON values.
 */
export type JSONObject = {[key: string]: JSONValue};

/**
 * Any value representable via a JSON file.
 */
export type JSONValue =
  | string
  | number
  | boolean
  | null
  | JSONArray
  | JSONObject;

/**
 * Determine if a given `JSONValue` is an object.
 * @param o to be checked.
 * @returns `true` if the given value is an object (not an array or primitive).
 */
export function isJSONObject(o: JSONValue): o is JSONObject {
  return typeof o === 'object' && o !== null && !Array.isArray(o);
}

/**
 * Determine if a given value can be used as a `JSONValue`. Checks recursively
 * if `o` is an object or an array.
 * @param o to be checked.
 * @returns `true` if the given value can be used as a `JSONValue`.
 */
export function isJSONValue(o: unknown): o is JSONValue {
  return (
    o === null ||
    typeof o === 'string' ||
    typeof o === 'number' ||
    typeof o === 'boolean' ||
    (Array.isArray(o) && o.every((v) => isJSONValue(v))) ||
    (typeof o === 'object' &&
      Object.entries(o).every(([, value]) => isJSONValue(value)))
  );
}
