import {NodeInstanceData, NodeIOExt, NodeType} from '../../../types';
import {Instruction} from '../types';
import {
  DEAD_END,
  VALUE_NOT_PRESENT,
  getFunc,
  FlowHeaders,
} from './converter-funcs';
import {getData} from './data';
import {BroadcastFunction} from './node.types';
import {Node} from './reactFlow.types';
import {Edge, FlowExportObject} from './reactFlow.types';

const FLOW_MAPPER_CACHE: Record<string, FlowMapper> = {};

export const getFlows = (domainName?: string): FlowMapper => {
  const data = getData(domainName);
  const listenerMap: FlowMapper = {};

  if (data.key && FLOW_MAPPER_CACHE[data.key]) {
    return FLOW_MAPPER_CACHE[data.key];
  }

  data.tabs.forEach((tab) => {
    const mappers = makeLookups(tab.data);
    referencePrevNext(mappers);
    const transformers = getTransformations(mappers);

    transformers.forEach((transform) => {
      Object.values(transform.nodes).forEach((node) => {
        const id = node.element.data?.id;
        if (id && node.element.data?.type === NodeType.listener) {
          if (!(id in listenerMap)) {
            listenerMap[id] = [];
          }
          listenerMap[id].push(node);
        }
      });
    });

    return transformers;
  });

  if (data.key) {
    FLOW_MAPPER_CACHE[data.key] = listenerMap;
  }

  return listenerMap;
};

const makeLookups = (obj: FlowExportObject<NodeInstanceData>): Mappers => {
  return (obj?.elements || []).reduce<Mappers>(
    (a, c) => {
      if ('source' in c) {
        a.edgeLookup[c.id] = {element: c};
      } else {
        a.nodeLookup[c.id] = {element: c};
      }
      return a;
    },
    {nodeLookup: {}, edgeLookup: {}}
  );
};

const getTransformations = (
  {nodeLookup, edgeLookup}: Mappers,

  suffix?: string
): Transformation[] => {
  suffix = suffix || Math.round(Math.random() * 10000).toString(10);
  // Have a guard for our while-loop.
  const transformations: Transformation[] = [];

  /** Start with an array of all our NodeLookups. */
  let freeNodes = Object.values(nodeLookup);
  let recentCount = freeNodes.length + 1;

  /**
   * For each iteration of this while-loop:
   *   - Confirm there are nodes not assigned a Transformation (freeNodes.length).
   *   - Take the first node of the list, assign it to a new Transformation.
   *   - Recursively do the same for every connected node.
   *   - Filter out of the freeNodes array any elements assigned to a Transformation.
   */
  let i = 0;
  while (freeNodes.length > 0 && freeNodes.length < recentCount) {
    recentCount = freeNodes.length;

    const transformation: Transformation = {
      /** This name doesn't seem to be important. It's just to identify when building */
      name: `transformation-${++i}-${suffix}`,
      edgeLookup,
      nodes: {},
    };

    transformations.push(transformation);

    const assigner = (nodeMapper: NodeMapper): void => {
      if (nodeMapper.transformation) {
        return;
      }
      nodeMapper.transformation = transformation;
      transformation.nodes[nodeMapper.element.id] = nodeMapper;

      nodeMapper.next?.forEach((child) => {
        child.elements.forEach((kid) => assigner(kid.element));
      });
      nodeMapper.prev?.forEach((child) => {
        child.elements.forEach((kid) => assigner(kid.element));
      });
    };
    assigner(freeNodes[0]);
    freeNodes = freeNodes.filter((node) => !node.transformation);
  }

  transformations.forEach((x) => {
    x.isComplete = Object.values(x.nodes).every((y) => y.isFull);
  });
  return transformations;
};

const referencePrevNext = ({nodeLookup, edgeLookup}: Mappers): NodeLookup => {
  const makeConnectors = (
    mapper: NodeMapper,
    putName: 'inputs' | 'outputs',
    from: 'source' | 'target',
    to: 'source' | 'target',
    arrayName: 'next' | 'prev'
  ): void => {
    if (mapper.element.data?.[putName]) {
      const puts = mapper.element.data[putName];
      if (puts) {
        // @ts-expect-error pretty sure this is right...
        mapper[arrayName] = puts.map((put) => {
          const elements = Object.values(edgeLookup)
            .map((x) => x.element)
            .filter((edge) => {
              return (
                edge[from] === mapper.element.id &&
                edge[`${from}Handle`] === put.slug
              );
            })
            .map((edge): NodeIOPut => {
              if (to === 'source') {
                return {
                  source: edge.source,
                  sourceHandle: edge.sourceHandle,
                  element: nodeLookup[edge.source],
                };
              } else {
                return {
                  target: edge.target,
                  targetHandle: edge.targetHandle,
                  element: nodeLookup[edge.target],
                };
              }
            });
          return {
            label: put.label,
            type: put.type,
            slug: put.slug,
            elements,
          };
        });
      }
    }
  };
  // Make the connections between nodes
  Object.values<NodeMapper>(nodeLookup).forEach((node) => {
    makeConnectors(node, 'outputs', 'source', 'target', 'next');
    makeConnectors(node, 'inputs', 'target', 'source', 'prev');
  });

  // Identify any that are not sufficiently wired up.
  Object.values<NodeMapper>(nodeLookup).forEach((node) => {
    node.isFull = [...(node.prev || []), ...(node.next || [])].every(
      (x) => x.elements.length
    );
  });
  return nodeLookup;
};
const getFieldValues = (
  refNode: Node<NodeInstanceData>
): Record<string, unknown> => {
  return (
    refNode.data?.fields?.reduce<Record<string, unknown>>((a, c) => {
      a[c.slug || ''] = c.value;
      return a;
    }, {}) || {}
  );
};

export const triggerTransformation = async (
  nodeMapper: NodeMapper,
  inst: Instruction,
  broadcast: BroadcastFunction,
  showId: string,
  structure: XMLDocument
): Promise<void> => {
  const nodeLookup = nodeMapper.transformation?.nodes;
  const edgeLookup = nodeMapper.transformation?.edgeLookup;
  if (!edgeLookup || !nodeLookup) {
    return;
  }
  /* Higher-order function made for calling later. It allows us to treat inputs and
   * outputs essentially the same.
   */
  const applyToOutputEdges = applyToOutputEdgesCore(edgeLookup, nodeLookup);

  /* create simplified model to keep track of the state of the flow instance. */
  const nodes: LocalNode[] = Object.values(nodeLookup).map((x) => {
    return {id: x.element.id};
  });
  const edges: LocalEdge[] = Object.values(edgeLookup).map((x) => {
    return {
      id: x.element.id,
      source: x.element.source,
      sourceHandle: x.element.sourceHandle,
      target: x.element.target,
      targetHandle: x.element.targetHandle,
    };
  });

  /* Perform the call from the triggered node */
  const first = nodes.find((x) => x.id === nodeMapper.element.id);
  if (!first) return;

  first.isPressurized = true;
  first.isSpent = true;

  const refNode = nodeLookup[first.id].element;
  if (!refNode.data) return;

  const initialFunc = await getFunc(refNode.data.id, 'async');

  const headers: FlowHeaders = {
    showId,
    instruction: inst,
    /* The following values will be mutated in the triggering node via `initialFunc` below */
    batter: null,
    bench: [],
    team: [],
    selector: '',
  };

  const value = initialFunc({
    node: refNode,
    data: {instruction: inst},
    broadcast,
    headers,
    structure,
  });
  if (value === DEAD_END || value === undefined) {
    return;
  }
  applyToOutputEdges(first.id, value, edges, {
    isPressurized: true,
  });

  /* Handle any no-input nodes (this may be unnecessary, but I'm doing it
   * for a slight efficiency gain and to be more deterministic in the order
   * that nodes are processed) */
  nodes
    .filter((x) => !x.isSpent)
    .forEach((node) => {
      const refNode = nodeLookup[node.id].element;
      if (!refNode.data) return;
      // Listeners don't work without passing along a topic and meta.
      if (refNode.data.type === NodeType.listener) return;
      if (!refNode.data.inputs?.length) {
        const thisNode = nodes.find((x) => x.id === refNode.id);
        const fieldVals = getFieldValues(refNode);
        const fn = getFunc(refNode.data.id);
        const value = fn({
          node: refNode,
          data: fieldVals,
          broadcast,
          headers,
          structure,
        });
        if (!thisNode || value === DEAD_END || value === undefined) return;
        applyToOutputEdges(thisNode.id, value, edges, {
          isPressurized: false,
        });
        thisNode.isPressurized = false;
        thisNode.isSpent = true;
      }
    });
  /*
   * isSpent means that the node or edge has nothing of value to us anymore:
   * - A node that's completed its computation and passed its values to its output edges.
   * - An edge whose downstream-connected node has already fired.
   */
  const getSpentCount = (): number =>
    [...nodes, ...edges].filter((x) => x.isSpent).length +
    edges.filter((x) => 'value' in x).length;

  let spentCount = 0;

  /* Work through the rest of the nodes iteratively */
  while (getSpentCount() > spentCount) {
    spentCount = getSpentCount();
    const filteredNodes = nodes.filter((x) => !x.isSpent);

    // Loop with a for-await-of since forEach won't respect await. We want this
    // for loop nested in the while loop to run synchronously because each node
    // in the flow editor must run sequentially, allowing them to run in
    // parallel is undefined behavior.
    // eslint-disable-next-line no-await-in-loop
    for await (const node of filteredNodes) {
      const refNode = nodeLookup[node.id].element;
      if (!refNode.data) continue;

      /* Confirm all edge inputs have values. */
      const myInputEdges = edges.filter((x) => x.target === node.id);
      const areEdgesReady =
        myInputEdges.filter((x) => 'value' in x).length == myInputEdges.length;

      if (myInputEdges.length && !areEdgesReady) continue;

      /* Create the object to pass to the func */
      const funcInput = nodeLookup[node.id].element.data?.inputs?.reduce<
        Record<string, unknown>
      >((a, input) => {
        const key = input.slug;
        const edge = myInputEdges.find((x) => x.targetHandle === input.slug);

        if (!edge && !input.value) {
          /* With no edge input, `input.value` defaults to an absence symbol */
          a[key] = VALUE_NOT_PRESENT;
        } else {
          /* There's either an edge input or an `input.value` */
          a[key] = input.value || edge?.value;
        }

        return a;
      }, {});

      if (!funcInput) {
        continue;
      }

      /* If it's an unpressurized listener, set isSpent=true, but don't run
       *  the function. All other cases, set isSpent=true and run function  */
      const isPressurized = myInputEdges.some((x) => x.isPressurized);

      if (!isPressurized && refNode.data.type === NodeType.listener) {
        node.isSpent = true;
      } else {
        const myFunction = await getFunc(refNode.data.id, 'async');

        /* Babel or tsc wraps `AsyncFunction` in `Function` so `myFunction.constructor.name`
         *  is useless. `await` of `Function` seems not to be a problem, though.  */
        const value = myFunction({
          node: refNode,
          data: funcInput,
          broadcast,
          headers,
          structure,
        });

        const thisNode = nodes.find((x) => x.id === refNode.id);
        if (thisNode) {
          thisNode.isPressurized = isPressurized;
          thisNode.isSpent = true;
          if (value !== DEAD_END && value !== undefined) {
            applyToOutputEdges(thisNode.id, value, edges, {
              isPressurized,
            });
          }
        }
      }
      myInputEdges.forEach((x) => (x.isSpent = true));
    }
  }
};

const applyToOutputEdgesCore =
  (edgeLookup: EdgeLookup, nodeLookup: NodeLookup) =>
  (
    nodeId: string,
    valueObj: Record<string, unknown>,
    edges: LocalEdge[],
    obj: Partial<Omit<LocalEdge, 'id'>>
  ): void => {
    // If there are no outputs, don't bother.
    if (!nodeLookup[nodeId].element.data?.outputs?.length) return;
    Object.keys(valueObj).forEach((handle) => {
      // console.log('HANDLE', handle);
      const value = valueObj[handle];
      const edgeIds = Object.values(edgeLookup)
        .filter(({element: x}) => {
          // console.log(x.sourceHandle, handle, x.source, nodeId);
          return x.source === nodeId && x.sourceHandle === handle;
        })
        .map((x) => x.element.id);
      // console.log('❤️‍🔥', edgeIds, nodeId, handle);
      for (let i = 0; i < edges.length; i++) {
        if (edgeIds.includes(edges[i].id)) {
          edges[i] = Object.assign(edges[i], obj, {value});
        }
      }
    });
  };

type NodeLookup = Record<string, NodeMapper>;
type EdgeLookup = Record<string, EdgeMapper>;
type FlowMapper = Record<string, NodeMapper[]>;

type Mappers = {
  nodeLookup: NodeLookup;
  edgeLookup: EdgeLookup;
};

type Transformation = {
  name: string;
  edgeLookup: EdgeLookup;
  nodes: NodeLookup;
  isComplete?: boolean;
  func?: () => void;
};

type EdgeMapper = {
  element: Edge<NodeInstanceData>;
  next?: Edge<NodeInstanceData>[];
  prev?: Edge<NodeInstanceData>[];
};

type NodeMapper = {
  element: Node<NodeInstanceData>;
  next?: NodeIOExt<NodeIOOutput>[];
  prev?: NodeIOExt<NodeIOInput>[];
  isFull?: boolean;
  transformation?: Transformation;
};

type NodeIOPut = NodeIOInput | NodeIOOutput;

type NodeIOInput = {
  source: string;
  sourceHandle?: string | null;
  element: NodeMapper;
  value?: string;
};

type NodeIOOutput = {
  target: string;
  targetHandle?: string | null;
  element: NodeMapper;
};

type LocalProps = {
  id: string;
  /** Has this node resolved to a value or been determined to be impossible to fire? */
  isSpent?: boolean;
  /** Only signals downstream of listeners are pressurized. Without pressure, a listener cannot fire. */
  isPressurized?: boolean;
};

type LocalNode = LocalProps;

type LocalEdge = LocalProps & {
  value?: unknown;
} & Pick<Edge, 'source' | 'sourceHandle' | 'target' | 'targetHandle'>;
