export {
  backgroundSchema,
  borderSchema,
  layoutSchema,
  opacitySchema,
  positionSchema,
  sizingSchema,
  spacingSchema,
  typographySchema,
} from './styling';

export type {Align} from './styling';
