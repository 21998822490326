import {useEffect} from 'react';

export function useExternalScript(options: UseExternalScriptOptions): void {
  useEffect(() => {
    if (document.getElementById(options.id) !== null) {
      return;
    }
    const script = document.createElement('script');
    script.id = options.id;
    script.src = options.src;
    script.async = true;
    if (typeof options.integrity === 'string') {
      script.integrity = options.integrity;
    }
    if (typeof options.crossOrigin === 'string') {
      script.crossOrigin = options.crossOrigin;
    }
    if (typeof options.onload === 'function') {
      script.onload = options.onload;
    }
    document.body.appendChild(script);
    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script);
    };
  }); // omit options so it only ever runs once
}

type UseExternalScriptOptions = Pick<HTMLScriptElement, 'src' | 'id'> &
  Partial<Pick<HTMLScriptElement, 'integrity' | 'crossOrigin' | 'onload'>>;
