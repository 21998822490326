import type {JSONObject, JSONValue} from '../../types';

export function isApiInstruction(
  action: ApiInstruction
): action is ApiInstruction {
  return (
    typeof action.meta === 'object' &&
    !Array.isArray(action.meta) &&
    action.meta !== null
  );
}

export interface ApiInstruction {
  id: string;
  kind: string;
  meta: JSONValue;
}

export interface Instruction {
  type: string;
  meta: JSONObject;
}

interface ShowById {
  id: string;
  /**
   * The sequence of actions and markers which have occurred for this show.
   */
  showInstructions: ApiInstruction[];
}

export interface GetShowInstructions {
  showById: ShowById | null;
}
