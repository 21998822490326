import {useMachine} from '@xstate/react';
import type {TUnion} from '@sinclair/typebox';
import {useObservableState, useSubscription} from 'observable-hooks';
import {
  FC,
  MutableRefObject,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import {filter, map, mergeMap, partition, Observable, Subject} from 'rxjs';
import {assign, createMachine, send} from 'xstate';
import {
  GetShowInstructions,
  ApiInstruction,
  Instruction,
  isApiInstruction,
} from './types';
import {CollectingBehaviorSubject} from './CollectingBehaviorSubject';
import {
  createInstructionValidator,
  globalVariable,
  isNamespaced,
  localVariable,
  partition as arrayPartition,
  storage,
  Broadcaster,
  DeriveInstruction,
  FLOW_IGNORE,
  InstructionSchema,
  ModuleIdentifiers,
  isAboutMe,
} from '../../helpers';
import {Registry} from '../../registry';
import {getFlows, triggerTransformation} from './transformations/converter';
import {BroadcastFunction} from './transformations/node.types';
import {isJSONObject, JSONObject} from '../../types';
import {useModuleIdentifiers} from '../useModuleIdentifiers';
import {useTrackInstruction} from '../useTrackInstruction';

export interface ShowInstructionsContextValue {
  currentPage?: AppPage;
  /** Id of the show whose instructions are being handled. */
  showId: string;
  simpleBroadcast: BroadcastFunction;
  subject: Subject<Instruction[] | Error>;
}

export type GetShowInstructionsResult = {
  data?: GetShowInstructions;
  error?: Error;
};

export interface AppPage {
  /** Pathname of the page. */
  pathname: string;
  /** Hierarchical structure of the tree of modules that make up the page. */
  structure: XMLDocument;
}

/**
 * Explicit path used to indicate the page should be used for fallback (404)
 * content. If this ever changes check other places using this to make sure
 * validation related Regular Expressions are updated to match the new value.
 */
export const FALLBACK_PAGE_PATH = '/*';

const FALLBACK_APP_PAGE: AppPage | undefined =
  typeof document !== 'undefined'
    ? {
        pathname: FALLBACK_PAGE_PATH,
        structure: document.implementation.createDocument(null, 'Root', null),
      }
    : undefined;

type FetchInstructionsFn = (
  id: string,
  sinceInstructionId?: string
) => Promise<GetShowInstructionsResult>;

export interface ShowInstructionsProviderProps {
  /** URL to which instructions will be transmitted as analytics */
  analyticsEndpoint?: string;
  /** Token used to identify a specific guest anonymously */
  analyticsToken?: string;
  /** Contains structured page data for each path */
  appPages?: AppPage[];
  /**
   * Whether the context provider should automatically fetch instructions when
   * mounting. This option is intended to be used for tests, to avoid the "not
   * wrapped in act" warning
   * @default true
   */
  autoFetch?: boolean;
  /** Domain on which the `showId` was viewed */
  domainName?: string;
  /**
   * Function used to retrieve new instructions, performs no fetches if not
   * provided. This can be used to prevent retrieving instructions until the
   * `showId` can be confidently provided.
   */
  fetchInstructions?: FetchInstructionsFn;
  /**
   * If set to `true` will be treated as though the provider is loaded
   * regardless of internal state.
   */
  isLoaded?: boolean;
  /** Id of the show whose instructions are being handled. */
  showId: string;
}

/**
 * @private Exported for testing purposes only
 */
export const ShowInstructionsContext = createContext<
  ShowInstructionsContextValue | undefined
>(undefined);
ShowInstructionsContext.displayName = 'ShowInstructionsContext';

/**
 * `useContext` wrapper for `ShowInstructionsContext` - prevents misuse of the context and fails fast.
 */
const useShowInstructionsContext = (): ShowInstructionsContextValue => {
  const context = useContext(ShowInstructionsContext);
  if (context === undefined) {
    throw new Error(
      'useShowInstructionsContext must be used within a ShowInstructionsProvider'
    );
  }
  return context;
};

/**
 * The list of instruction types marked as "internal only" by the presence of
 * the `FLOW_IGNORE` property in the schema.
 */
const internalTopics: string[] = [];

/**
 * Context `Provider` to create and hold show instructions.
 */
export const ShowInstructionsProvider: FC<ShowInstructionsProviderProps> = (
  props
) => {
  const {
    analyticsEndpoint,
    analyticsToken,
    appPages = [],
    autoFetch = true,
    children,
    domainName,
    fetchInstructions,
    showId,
  } = props;
  const track = useTrackInstruction({
    analyticsEndpoint,
    analyticsToken,
    domainName,
    showId,
  });
  // Collects instructions which have been broadcast for delivery to modules
  const subjectRef = useRef(new CollectingBehaviorSubject<Instruction>());
  const value = useMemo(
    () => ({showId, subject: subjectRef.current}),
    [showId]
  );
  const earlyInstructions = useRef<Instruction[]>([]);
  const isLoaded = useRef(props.isLoaded ?? false);
  // Set up broadcasting which will track every instruction at "broadcast"
  // passing along the `source` information as part of the tracking
  const simpleBroadcast: BroadcastFunction = useMemo(() => {
    return (instruction, source = 'internal') => {
      track(instruction, source);
      if (isLoaded.current || internalTopics.includes(instruction.type)) {
        subjectRef.current.next([instruction]);
      } else {
        earlyInstructions.current.push(instruction);
      }
    };
  }, [track]);
  // If debugging features are on then add a `broadcast` function to the window
  // This gives a way for developers to test out broadcasting an arbitrary
  // instruction in the browser
  useEffect(() => {
    if (
      storage.getItem('debug:broadcast') === 'true' &&
      typeof window !== 'undefined' &&
      window.location.hostname === 'localhost'
    ) {
      // @ts-expect-error adding to global
      window.broadcast = simpleBroadcast;
      return () => {
        // @ts-expect-error deleting from global
        delete window.broadcast;
      };
    }
  }, [simpleBroadcast]);
  // If instruction logging is turned on this subscription will log everything
  // coming through `subject`.
  useSubscription(value.subject, (instructions) => {
    // someone turned on logging
    if (storage.getItem('logInstructions') === 'true') {
      console.info('ℹ️', JSON.stringify(instructions));
    }
  });
  // TODO: This uses knowledge of another internal component to get the last
  // navigated pathname
  const appPages$ = useMemo(
    () =>
      value.subject.pipe(
        filter((value): value is Instruction[] => Array.isArray(value)),
        mergeMap((instructions) => instructions),
        filter((instruction) => instruction.type === 'Router:on-navigate'),
        map((instruction) =>
          appPages.find(
            (page) => page.pathname === instruction.meta.currentPath
          )
        ),
        map(
          (appPage) =>
            appPage ??
            appPages.find((page) => page.pathname === FALLBACK_PAGE_PATH) ??
            FALLBACK_APP_PAGE
        )
      ),
    [appPages, value.subject]
  );
  const currentPage = useObservableState(appPages$, FALLBACK_APP_PAGE);
  // Create the subscription to process flows
  useSubscription(value.subject, (instructions) => {
    if (Array.isArray(instructions)) {
      const flows = getFlows(domainName);
      /*
       * Set all the variables BEFORE allowing flows to react to
       * `Global:variable:on-set` since multiple variables can be set at once and
       * multiple `Global:variable:set` (or `variable:set`) instructions can be
       * in an instruction batch it is necessary they are all processed before
       * flows which may be reacting to those instructions are executed in order
       * to ensure consistent flow behavior.
       */
      const initialVarSet: [Instruction, Instruction] = [
        {type: 'Global:variable:on-set', meta: {}},
        {type: 'variable:on-set', meta: {}},
      ];
      const [setters, actions] = arrayPartition(
        instructions,
        (instruction) =>
          instruction.type === 'Global:variable:set' ||
          instruction.type === 'variable:set'
      );
      // reduce the setting instructions a global and local 'on-set' instruction
      const onSet: Instruction[] = setters
        .reduce(
          (memo: (Instruction | undefined)[], instruction) => {
            let [global, local] = memo;
            if (instruction.type === 'Global:variable:set') {
              global = global ?? initialVarSet[0];
              Object.assign(global.meta, instruction.meta);
            } else if (instruction.type === 'variable:set') {
              local = local ?? initialVarSet[1];
              Object.assign(local.meta, instruction.meta);
            }
            return [global, local];
          },
          [undefined, undefined]
        )
        .filter(
          (instruction): instruction is Instruction =>
            typeof instruction !== 'undefined'
        );
      // prepend the 'on-set' instructions to the list of actions then process
      // the flows associated with all of the 'actions' in the batch
      (onSet ?? []).concat(actions).forEach((inst) => {
        if (inst.type === 'Global:variable:on-set') {
          setVariables(inst.meta, globalVariable(showId));
        } else if (inst.type === 'variable:on-set') {
          setVariables(inst.meta, localVariable(showId));
        }
        const structure = currentPage?.structure;
        if (structure && inst.type in flows) {
          flows[inst.type].forEach((node) => {
            triggerTransformation(
              node,
              inst,
              simpleBroadcast,
              showId,
              structure
            );
          });
        }
      });
    }
  });

  // Create the instructions polling state machine
  const showInstructionsMachine = useMemo(
    () => createInstructionFetchMachine({subject: value.subject}),
    [value]
  );
  const [state, dispatch] = useMachine(() => showInstructionsMachine);
  const getInstructions = useMemo(
    () =>
      typeof fetchInstructions === 'function'
        ? fetchInstructions.bind(null, showId)
        : undefined,
    [fetchInstructions, showId]
  );
  // Only request instructions if `fetchInstructions` is provided in props,
  // waits until `getInstructions` exists before fetching first batch
  useEffect(() => {
    if (
      state.matches('WAITING') &&
      autoFetch &&
      typeof getInstructions === 'function'
    ) {
      dispatch({type: 'FETCH'});
      // TODO: Move this to be invoked on entry of PENDING
      getInstructions(state.context.sinceInstructionId)
        .then((result) => {
          if (
            typeof result.data?.showById !== 'undefined' &&
            result.data?.showById !== null
          ) {
            dispatch({
              type: 'REQUEST_COMPLETE',
              instructions: result.data.showById.showInstructions,
            });
          } else if (typeof result.error !== 'undefined') {
            dispatch({
              type: 'REQUEST_ERROR',
              error: result.error,
            });
          } else {
            dispatch({
              type: 'REQUEST_COMPLETE',
              instructions: [],
            });
          }
        })
        .catch((reason: unknown) => {
          dispatch({
            type: 'REQUEST_ERROR',
            error: reason instanceof Error ? reason : new Error(),
          });
        });
    } else if (currentPage && !isLoaded.current && state.matches('DONE')) {
      // The state machine moves to `DONE` (or `ERROR`) after the first fetch is
      // complete and so indicate the initial fetch of instructions has been
      // completed
      onLoad(isLoaded, earlyInstructions, value.subject);
    }
  }, [autoFetch, currentPage, getInstructions, dispatch, state, value.subject]);
  // When forced into an `isLoaded` state send any collected instructions
  useEffect(() => {
    if (props.isLoaded) {
      onLoad(isLoaded, earlyInstructions, value.subject);
    }
  }, [props.isLoaded, value.subject]);

  const contextValue = useMemo(() => {
    const result: ShowInstructionsContextValue = {
      currentPage,
      simpleBroadcast,
      ...value,
    };
    return result;
  }, [currentPage, simpleBroadcast, value]);
  return (
    <ShowInstructionsContext.Provider value={contextValue}>
      {children}
    </ShowInstructionsContext.Provider>
  );
};

/**
 * Set "loaded" state to `true` and push any collected instructions into
 * `subject` before clearing them.
 * @param isLoaded `Ref` for the "loaded" state
 * @param earlyInstructions `Ref` for the collected instructions
 * @param subject into which `earlyInstructions` will be pushed
 */
function onLoad(
  isLoaded: MutableRefObject<boolean>,
  earlyInstructions: MutableRefObject<Instruction[]>,
  subject: Subject<Error | Instruction[]>
): void {
  isLoaded.current = true;
  // Send any instructions that came in before first batch was loaded
  subject.next(earlyInstructions.current);
  // Clear out the early instructions; just in case
  earlyInstructions.current = [];
}

const setVariables = (variables: JSONObject, prefix: string): void => {
  Object.entries(variables).forEach(([key, value]) => {
    storage.setItem(`${prefix}${key}`, JSON.stringify(value));
  });
};

/**
 * Provides the `showId` for which instructions are currently being broadcast
 * and received.
 * @returns the `showId` of the current show instructions.
 */
export const useShowId = (): string => {
  const {showId} = useShowInstructionsContext();
  return showId;
};

/**
 * Gives access to instruction messages for context's `showId` for instructions
 * which match the given `schema`.
 *
 * **WARN** Do not use this unless there are no `ModuleIdentifiers` for your
 * component.
 *
 * @param schema of the instructions to be broadcast and received
 * @returns object with show instructions observable and a function to
 * broadcast a new instruction.
 * @private exported for internal usage, using this API is considered undefined
 * behavior.
 */
export function useShowInstructions<
  InstructionTypes extends InstructionSchema[]
>(schema: TUnion<InstructionTypes>): ShowInstructions<InstructionTypes>;
/**
 * Gives access to instruction messages for context's `showId` for instructions
 * which match the given `schema`.
 * @param schema of the instructions to be broadcast and received
 * @param identifiers of the module broadcasting and receiving; if provided the
 * resulting `broadcast` function will always include it in the broadcasts.
 * @returns object with show instructions observable and a function to
 * broadcast a new instruction.
 */
export function useShowInstructions<
  InstructionTypes extends InstructionSchema[]
>(
  schema: TUnion<InstructionTypes>,
  identifiers: ModuleIdentifiers
): ShowInstructions<InstructionTypes>;

export function useShowInstructions<
  InstructionTypes extends InstructionSchema[]
>(
  schema: TUnion<InstructionTypes>,
  identifiers?: ModuleIdentifiers
): ShowInstructions<InstructionTypes> {
  type ValidatedInstruction = DeriveInstruction<InstructionTypes>;
  const isValidatedInstruction = useMemo(
    () => createInstructionValidator(schema),
    [schema]
  );
  const {currentPage, simpleBroadcast, subject} = useShowInstructionsContext();
  const moduleIdentifiers = useModuleIdentifiers(identifiers);

  const about = useMemo(
    () => (moduleIdentifiers ? `#${moduleIdentifiers.id}` : null),
    [moduleIdentifiers]
  );

  // The `broadcast` function always adds `about` to the instruction, if given
  const broadcast: Broadcaster<InstructionTypes> = useCallback(
    (instruction) => {
      if (isValidatedInstruction(instruction)) {
        const instructionWithAbout = {
          type: instruction.type,
          meta: {
            ...instruction.meta,
            ...(typeof about === 'string' ? {about} : undefined),
          },
        };
        simpleBroadcast(instructionWithAbout, moduleIdentifiers);
      } else {
        console.warn({
          tag: 'useShowInstructions',
          msg: 'Invalid instruction',
          instruction,
        });
      }
    },
    [about, isValidatedInstruction, moduleIdentifiers, simpleBroadcast]
  );
  const [errors, instructions] = useMemo(() => {
    // Flatten the instructions so receivers do not get them in batches
    const [errors$, instructions$] = partition(
      subject,
      (next): next is Error => next instanceof Error
    );
    const observable: Observable<ValidatedInstruction> = instructions$.pipe(
      mergeMap((value) => {
        if (Array.isArray(value)) {
          return value;
        } else {
          return [value];
        }
      }),
      filter(isValidatedInstruction)
    );
    return [errors$, observable];
  }, [isValidatedInstruction, subject]);
  const filteredInstructions = useMemo(() => {
    const structure = currentPage?.structure;
    if (structure && about) {
      return instructions.pipe(
        filter((inst) => {
          return isAboutMe(structure, inst?.meta?.about, about);
        })
      );
    } else {
      return instructions;
    }
  }, [about, currentPage, instructions]);
  useSubscription(errors, (error) =>
    console.error({tag: 'InstructionError', error})
  );
  return {observable: filteredInstructions, broadcast};
}

export interface ShowInstructions<Schema extends InstructionSchema[]> {
  observable: Observable<DeriveInstruction<Schema>>;
  broadcast: Broadcaster<Schema>;
}

type TransitionEvent<
  Kind extends string,
  Data extends Record<string, unknown> = Record<string, never>
> = Data extends Record<string, never> ? {type: Kind} : {type: Kind} & Data;

interface InstructionBaseContext {
  subject: Subject<Instruction[] | Error>;
}

interface InstructionTypestateContext {
  sinceInstructionId?: string;
  error?: Error;
  lastInstructions?: ApiInstruction[];
}

type InstructionContext = InstructionBaseContext & InstructionTypestateContext;

type Action =
  | TransitionEvent<'FETCH'>
  | TransitionEvent<'AWAIT_MORE'>
  | TransitionEvent<'REQUEST_ERROR', {error: Error}>
  | TransitionEvent<'REQUEST_COMPLETE', {instructions: ApiInstruction[]}>;

interface TS<
  Value extends string,
  Context extends InstructionTypestateContext = Record<string, never>
> {
  value: Value;
  context: InstructionBaseContext & Context;
}

type InstructionTypestate =
  | TS<'WAITING', {sinceInstructionId?: string}>
  | TS<'PENDING'>
  | TS<'ERROR', {error: Error}>
  | TS<'DONE', {lastInstructions: ApiInstruction[]}>;

const createInstructionFetchMachine = (
  options: InstructionBaseContext
): typeof showInstructionsMachine => {
  return showInstructionsMachine.withContext({
    subject: options.subject,
  });
};

const showInstructionsMachine = createMachine<
  InstructionContext,
  Action,
  InstructionTypestate
>(
  {
    id: 'instructions',
    initial: 'WAITING',
    states: {
      WAITING: {
        on: {
          FETCH: {
            target: 'PENDING',
          },
        },
      },
      PENDING: {
        on: {
          REQUEST_ERROR: {
            actions: assign({
              error: (_context, event) => event.error,
            }),
            target: 'ERROR',
          },
          REQUEST_COMPLETE: {
            actions: assign({
              lastInstructions: (_context, event) => {
                return event.instructions.filter(isApiInstruction);
              },
              sinceInstructionId: (context, event) => {
                if (event.instructions.length > 0) {
                  return event.instructions.slice(-1)[0].id;
                } else {
                  return context.sinceInstructionId;
                }
              },
            }),
            target: 'DONE',
          },
        },
      },
      ERROR: {
        entry: (context) => {
          if (context.error instanceof Error) {
            context.subject.next(context.error);
          }
        },
        on: {
          AWAIT_MORE: {
            target: 'WAITING',
          },
        },
        after: {
          5000: {
            actions: send({type: 'AWAIT_MORE'}),
          },
        },
      },
      DONE: {
        entry: ['pushInstructions'],
        on: {
          AWAIT_MORE: {
            target: 'WAITING',
          },
        },
        after: {
          5000: {
            actions: send({type: 'AWAIT_MORE'}),
          },
        },
      },
    },
  },
  {
    actions: {
      pushInstructions: (context) => {
        if (Array.isArray(context.lastInstructions)) {
          context.subject.next(
            context.lastInstructions.map((signal) => ({
              type: isNamespaced(signal.kind)
                ? signal.kind
                : `unknown:${signal.kind}`,
              meta: isJSONObject(signal.meta) ? signal.meta : {},
            }))
          );
        }
      },
      setSinceInstructionId: assign<InstructionContext, Action>({
        sinceInstructionId: (context) => {
          const lastInstructions = context.lastInstructions ?? [];
          if (lastInstructions.length > 0) {
            return lastInstructions.slice(-1)[0].id;
          } else {
            return context.sinceInstructionId;
          }
        },
      }),
    },
  }
);

/**
 * When a Module is registered find any internal instructions and add them to
 * the `internalTopics` list.
 */
Registry.on('register', (c) => {
  const instructionSchemas = c.instructions?.anyOf ?? [];
  for (const schema of instructionSchemas) {
    if (FLOW_IGNORE in schema && schema[FLOW_IGNORE] === true) {
      internalTopics.push(schema.properties.topic.const);
    }
  }
});
