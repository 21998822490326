import {Fragment, useMemo} from 'react';
import parse from 'html-react-parser';
import {Element} from 'domhandler';
import {DOMNode} from 'html-react-parser';

/**
 * Checks if the given `domNode` is a `<script>` element.
 * @param domNode to check for `tagName`
 * @returns `true` iff `domNode` is `<script>` element.
 */
function isScriptElement(domNode: DOMNode): domNode is Element {
  return (
    domNode instanceof Element && domNode.tagName.toLowerCase() === 'script'
  );
}

/**
 * Runs `content` through `parse` from `html-react-parser`.
 * replaces `<script>` tags. @see {@link isScriptElement}
 * @param content plain text or `html` markup.
 * @returns parsed text via `html-react-parser`.
 */
export const useParsedText = (
  content: string
): string | JSX.Element | JSX.Element[] => {
  return useMemo(
    () =>
      parse(content, {
        replace: (domNode) => {
          if (isScriptElement(domNode)) {
            return <Fragment />;
          }
        },
      }),
    [content]
  );
};
