import {type TUnion} from '@sinclair/typebox';
import {type JSONSchema7} from 'json-schema';
import {type ComponentAdmin} from '../types';
import {PRIVATE_FIELD_DATA} from './helper-constants';
import {isTypeBoxObject} from './typebox-helpers';

/**
 * Create a `ComponentAdmin` instance with inferred instruction types. This is
 * especially helpful for the `analyticsInstructionMask` function.
 */
export function makeComponentAdmin<Instructions extends TUnion>(
  props: ComponentAdmin<Instructions>
): ComponentAdmin<Instructions> {
  return props;
}

/**
 * Recursively mark all of the properties of `definition` with
 * `PRIVATE_FIELD_DATA`. **NOTE** Mutates the incoming `definition` by adding
 * the `PRIVATE_FIELD_DATA` key to each of the `properties`.
 */
export function markPrivateProperties(definition: JSONSchema7): void {
  if (isTypeBoxObject(definition)) {
    for (const value of Object.values(definition.properties)) {
      if (isTypeBoxObject(value)) {
        markPrivateProperties(value);
      } else {
        value[PRIVATE_FIELD_DATA] = true;
      }
    }
  }
}
