/**
 * Creates an array of elements split into two groups, the first
 * contains elements for which `predicate` returns `true`, the second
 * contains elements for which `predicate` returns `false` for. The predicate is
 * invoked with one argument: (value).
 *
 * @param array to be partitioned
 * @param predicate invoked per item in `array`
 * @returns Returns the array of grouped elements.
 * @example
 *
 * var users = [
 *   { 'user': 'barney',  'age': 36, 'active': false },
 *   { 'user': 'fred',    'age': 40, 'active': true },
 *   { 'user': 'pebbles', 'age': 1,  'active': false }
 * ];
 *
 * arrayPartition(users, function(o) { return o.active; });
 * // => objects for [['fred'], ['barney', 'pebbles']]
 */
export function partition<T>(
  array: T[],
  predicate: (value: T) => boolean
): [T[], T[]];

export function partition<T, S extends T>(
  array: T[],
  predicate: (value: T) => value is S
): [S[], T[]];

export function partition<T>(
  array: T[],
  predicate: (value: T) => boolean
): [T[], T[]] {
  const unmatched: T[] = [];
  const matched: T[] = [];
  for (const item of array) {
    if (predicate(item)) {
      matched.push(item);
    } else {
      unmatched.push(item);
    }
  }
  return [matched, unmatched];
}
